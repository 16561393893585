import BERENIS_CONFIG from './berenis/BERENIS_CONFIG'
import GRID_CAPACITY_CONFIG from './gridcapacity/GRID_CAPACITY_CONFIG'
import SIRAO_CONFIG from './sirao/SIRAO_CONFIG'

const APP = {
  BERENIS: 'berenis',
  GRID_CAPACITY: 'grid-capacity',
  SIRAO: 'sirao',
}
const CONFIG = {
  [APP.BERENIS]: BERENIS_CONFIG,
  [APP.GRID_CAPACITY]: GRID_CAPACITY_CONFIG,
  [APP.SIRAO]: SIRAO_CONFIG,
}

const origin = window.location.origin
let currentApp = APP.SIRAO
for (const app in CONFIG) {
  if (CONFIG[app].appUrl.match(origin)) {
    currentApp = app
    break
  }
}
const currentConfig = {
  ...CONFIG[APP.BERENIS],
  isBeta: CONFIG[currentApp].isBeta,
} //CONFIG[currentApp]

// Import the corresponding stylesheet
switch (currentApp) {
  case APP.BERENIS:
    import('./berenis/berenis.css')
    break
  case APP.GRID_CAPACITY:
    import('./gridcapacity/gridcapacity.css')
    break
  case APP.SIRAO:
    import('./sirao/sirao.css')
    break
  default:
}

export default currentConfig
